import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page--limited minh0" }
const _hoisted_2 = { class: "card flex1 minh0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_BillingTable = _resolveComponent("BillingTable")!
  const _component_BillingSupplierDialog = _resolveComponent("BillingSupplierDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.$t('billing_suppliers.title')
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_router_link, { to: $setup.routeName }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              icon: "pi pi-plus",
              label: "Nuovo report"
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BillingTable, {
        ref: "dataTable",
        filters: $setup.filters,
        "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ($setup.filters = $event)),
        isLoading: $setup.isLoading||$setup.exportInProgress,
        totalRecords: $setup.totalRecords,
        reports: $setup.reports,
        onUpdate: $setup.onUpdate,
        onExport: $setup.onExport,
        onEdit: $setup.onEdit
      }, null, 8, ["filters", "isLoading", "totalRecords", "reports", "onUpdate", "onExport", "onEdit"]),
      _createVNode(_component_BillingSupplierDialog, {
        visible: $setup.displayEditDialog,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ($setup.displayEditDialog = $event)),
        editValue: $setup.toEdit,
        onSaved: $setup.onSaved
      }, null, 8, ["visible", "editValue", "onSaved"])
    ])
  ]))
}