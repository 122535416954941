
import { PageHeader } from "@/components"

import { BillingTable } from "../../components"

import { BillingRoutesEnum }   from '../../router'
import { ref }                 from "vue";
import { UserType }            from "@/model/Entity";
import { formerList }          from "../../logic/FormerList";
import { DataTableFilterMeta } from 'primevue/datatable';
import { PrimeVueEvent }       from '@/model/common/PrimeVueTypes';
import moment                  from "moment";
import { useExport }           from "@/modules/billing/logic/export";
import BillingSupplierDialog   from "@/modules/billing/components/billing-supplier-dialog/BillingSupplierDialog.vue";
import { Report }              from "@/model/Report";
import { useMessage }          from "@plugins/Messages-plugin";

export default {
  components: {
    BillingSupplierDialog,
    PageHeader,
    BillingTable
  },

  setup() {
    const dataTable = ref();

    const routeName = {
      name: BillingRoutesEnum.BILLING_SUPPLIERS_FIND
    };

    const filters = ref<DataTableFilterMeta>( {
      type         : {
        value    : [],
        matchMode: "in"
      },
      business_name: {
        value: null,
        matchMode: "contains"
      },
      period: {
        value: [
          moment().subtract(1, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
        ],
        matchMode: "contains"
      }
    });

    const {
      isLoading,
      totalRecords,
      reports,
      loadReports
    } = formerList([
      UserType.SUPPLIER,
      UserType.TAKER
    ]);

    const {
      exportInProgress,
      onExport
          } = useExport( [
      UserType.SUPPLIER,
      UserType.TAKER
    ] )

    function onUpdate( params: PrimeVueEvent ) {
      params?.originalEvent && delete params.originalEvent;

      loadReports( params );
    }

    const { successMessage } = useMessage();
    const displayEditDialog  = ref( false );
    const toEdit             = ref<Report>();

    function onEdit( data: Report ) {
      toEdit.value            = data;
      displayEditDialog.value = true;
    }

    function onSaved() {
      successMessage( 'Report aggiornato!' );
      loadReports( dataTable.value.$refs.table.createLazyLoadEvent() );
    }

    return {
      isLoading,
      routeName,
      reports,
      totalRecords,

      filters,
      onUpdate,

      exportInProgress,
      onExport,

      displayEditDialog,
      onEdit,
      toEdit,
      onSaved,

      dataTable
    }
  }
}
