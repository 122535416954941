
import { computed, defineComponent, onMounted, ref, watch, } from 'vue';
import { useMessage }                                        from "@plugins/Messages-plugin";

import { PageHeader, ShiftToText } from "@/components";
import { supplierShifts }          from './supplierShifts';

import ChipFilters                     from "../../components/chip-fitlers/ChipFilters.vue";
import OrderTable                      from "../../components/order-table/OrderTable.vue";
import { unprocessedOrders }           from '../../logic/UnprocessedOrders';
import { zoneDetails }                 from './zoneDetails';
import { entityDetails }               from './entityDetails';
import { reportsService }              from '@services/reports.service';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { BillingRoutesEnum }           from '../../router';
import { CurrencyText }                from "@sharedComponents";

export default defineComponent( {
  components: {
    PageHeader,
    ShiftToText,
    OrderTable,
    ChipFilters,
    CurrencyText
  },

  props: {
    entityId: {
      type    : String,
      required: true
    },
    from    : {
      type    : String,
      required: true
    },
    to      : {
      type    : String,
      required: true
    },
    zoneId  : {
      type: String,
    }
  },

  setup( p ) {
    const router    = useRouter();
    const backRoute = computed( () => {
      return {
        name : BillingRoutesEnum.BILLING_SUPPLIERS_FIND,
        query: {
          date_from_at: p.from,
          date_to_at  : p.to,
          zone_id     : p.zoneId
        }
      } as RouteLocationRaw
    } )

    const {
            errorMessage,
            successMessage,
            confirmMessage,
          } = useMessage()

    /*Totale ordini*/
    const total_sys_amount = ref( 0 )

    /*Totale turni*/
    const total_shifts_amount = ref( 0 )

    /*Totale*/
    const total = computed( () => total_sys_amount.value + total_shifts_amount.value )

    const note      = ref( "" )
    const isLoading = ref<boolean>( false );

    async function onProcessClick() {
      if (total_sys_amount.value == null) {
        errorMessage( "Inserire il totale ordini" );
        return;
      }
      if (total_shifts_amount.value == null) {
        errorMessage( "Inserire il totale fasce" );
        return;
      }

      const rx = await confirmMessage(
          `Generare report per ${ entity.value?.business_name }?`,
          "Conferma elaborazione"
      )

      if (rx) {
        process()
      }
    }

    async function process() {
      try {
        isLoading.value = true

        await reportsService.processOrders( {
          entity_id: +p.entityId,
          zone_id  : p.zoneId ? +p.zoneId : null,

          date_from_at: p.from as string,
          date_to_at  : p.to as string,

          total_amount       : total.value,
          total_shifts_amount: total_shifts_amount.value,

          note: note.value
        } )

        router.replace( backRoute.value )
              .then( () => successMessage(
                  `Ordini elaborati con successo per ${ entity?.value.business_name }`
              ) );
      } catch (error) {
        errorMessage( "Generazione report non riuscita" );
      } finally {
        isLoading.value = false
      }
    }

    const {
            zone,
            loadZone
          } = zoneDetails( +p.zoneId );

    const zoneName = computed( () => zone.value?.name )

    const {
            entity,
            loadEntity,
          } = entityDetails( +p.entityId )

    const {
            shifts,
            loadShifts,
            isLoading: shiftsAreLoading,
          } = supplierShifts( +p.entityId, {
      date_from_at: p.from as string,
      date_to_at  : p.to as string,
      zone_id     : p.zoneId ? +p.zoneId : undefined,
    } )

    const {
            orders,
            totalRecords: ordersTotalRecords,
            isLoading   : ordersAreLoading,
            loadUnprocessedOrders
          } = unprocessedOrders( +p.entityId, {
      date_from_at: p.from as string,
      date_to_at  : p.to as string,
      zone_id     : p.zoneId ? +p.zoneId : undefined,
    } )

    async function loadZoneAndInitNote() {
      await loadZone();
      note.value = zone.value?.name;
    }

    async function loadTotal() {
      total_sys_amount.value = (await reportsService.kpi( {
        date_from_at: p.from as string,
        date_to_at  : p.to as string,
        entity_id   : +p.entityId,
        zone_id     : p.zoneId ? +p.zoneId : undefined,
      } )).total_sys_amount
    }

    onMounted( () => {
      loadTotal();
      loadEntity();
      loadShifts();
      loadUnprocessedOrders();

      if (p.zoneId) {
        loadZoneAndInitNote();
      }
    } )

    watch( shifts, calcShiftsTotal, { deep: true } )

    function calcShiftsTotal() {
      total_shifts_amount.value = shifts.value.reduce( ( acc, x ) => {
        return acc
            + x.weekday_range1_shifts_qty * x.weekday_range1_cost
            + x.weekday_range2_shifts_qty * x.weekday_range2_cost
            + x.weekday_range3_shifts_qty * x.weekday_range3_cost
      }, 0 )
    }


    return {
      shifts,
      shiftsAreLoading,

      total,
      total_sys_amount,
      total_shifts_amount,
      note,
      entity,
      zoneName,

      isLoading,
      onProcessClick,

      orders,
      ordersTotalRecords,
      ordersAreLoading,
      loadUnprocessedOrders,
    }
  }
} )
