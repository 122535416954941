import { PrimeVueEvent } from "@/model/common/PrimeVueTypes";
import { PaginatedResponse } from "@/model/PaginatedResponse";
import { UnprocessedOrder } from "@/model/Report";
import { useMessage } from "@plugins/Messages-plugin";
import { reportsService, UnprocessedEntitiesParams } from "@services/reports.service";
import { computed, ref } from "vue";

export function unprocessedOrders(
  entity_id: number,
  params: UnprocessedEntitiesParams
) {
  const { errorMessage } = useMessage();
  const isLoading = ref(false)

  const response      = ref<PaginatedResponse<UnprocessedOrder>>(null);
  const orders        = computed( () => response.value?.data);
  const totalRecords  = computed( () => response.value?.total);

  async function loadUnprocessedOrders(e?: PrimeVueEvent) {
    e?.originalEvent && delete e.originalEvent;

    try {
      isLoading.value = true;
      
      response.value = await reportsService.unprocessedOrders({
        entity_id,
        ...params,
        
        page    : e?.page ?? 1,
        per_page: e?.rows ?? 10,
      });
    } catch (error) {
      errorMessage("Caricamento ordini non riuscito");
    } finally {
      isLoading.value = false;
    }
  }
  
  return {
    isLoading,
    response,
    orders,
    totalRecords,
    loadUnprocessedOrders
  }

}