import { ref } from "vue";
import { useMessage } from "@plugins/Messages-plugin";

import { 
  reportsService, 
  SupplierShift, 
  UnprocessedEntitiesParams 
} from "@services/reports.service";

export function supplierShifts(
  supplierId: number,
  params: UnprocessedEntitiesParams
) {
  const { errorMessage } = useMessage();
  const isLoading = ref(false)
  const shifts    = ref<SupplierShift[]>([])

  async function loadShifts() {
    try {
      isLoading.value = true
      shifts.value    = await reportsService.getSupplierShifts(
        supplierId, params
      );
    } catch (error) {
      errorMessage("Errore durante il caricamento dei turni")
    } finally {
      isLoading.value = false
    }
  }

  return {
    shifts,
    isLoading,
    loadShifts
  }
}