import { Entity } from '@/model/Entity';
import { CRUD } from './base/crud';

class EntitiesService extends CRUD<Entity, any> {
    readonly endPoint = `entities`;
}

export const entitiesService = new EntitiesService();



