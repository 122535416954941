import { Zone } from "@/model/Zone";
import { useMessage } from "@plugins/Messages-plugin";
import { zonesService } from "@services/zones.service";
import { ref } from "vue";

export function zoneDetails(zoneId: number) {
  const { errorMessage } = useMessage();
  const isLoading = ref(false);

  const zone = ref<Zone>();

  async function loadZone() {
    try {
      isLoading.value = true;

      zone.value = await zonesService.getById(zoneId)
    } catch (error) {
      errorMessage("Caricamento fornitore non riuscito");
    } finally {
      isLoading.value = false;
    }
  }

  return {
    zone,
    isLoading,
    loadZone
  }
}