import { Entity } from "@/model/Entity";
import { useMessage } from "@plugins/Messages-plugin";
import { entitiesService } from "@services/entities.service";
import { ref } from "vue";

export function entityDetails(entityId: number) {
  const { errorMessage } = useMessage();
  const isLoading = ref(false);

  const entity = ref<Entity>();

  async function loadEntity() {
    try {
      isLoading.value = true;

      entity.value = await entitiesService.getById(entityId)
    } catch (error) {
      errorMessage("Caricamento fornitore non riuscito");
    } finally {
      isLoading.value = false;
    }
  }

  return {
    entity,
    isLoading,
    loadEntity
  }
}